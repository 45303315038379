import type { FC } from 'react'
import { CwsGrid, CwsGridCol, CwsGridRow, CwsH1, CwsP, CwsSection } from '@components/cws'
import type { WelcomeNote } from '@api/content'

interface WelcomeOnBoardNoteProps {
  content: WelcomeNote
}

export const WelcomeOnBoardNote: FC<WelcomeOnBoardNoteProps> = ({ content }) => {
  return (
    <CwsSection className="cws-pt-l">
      <CwsGrid>
        <CwsGridRow justifyContent="center">
          <CwsGridCol lg="10">
            <div className="text-center">
              <CwsH1>{content.title}</CwsH1>
              <CwsP size="lead">{content.content}</CwsP>
            </div>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
      <hr className="text-medium-grey mt-12 mb-6" />
    </CwsSection>
  )
}

export default WelcomeOnBoardNote
