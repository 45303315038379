import type { FC } from 'react'
import { CwsGrid, CwsGridCol, CwsGridRow, CwsH3, CwsIcon, CwsP, CwsSection } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import Link from 'next/link'
import moduleStyles from './ShipSelection.module.scss'
import clsx from 'clsx'
import type { CatalogFromContent } from '@api/content'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { device600 } from '@ui/hooks/useResize'

interface ShipSelectionProps {
  catalogs: CatalogFromContent[]
}

export const ShipSelection: FC<ShipSelectionProps> = ({ catalogs = [] }) => {
  const { translate } = useTranslate()
  const isMobile = useMediaLess(device600)

  return (
    <CwsSection className="cws-pt-l">
      <CwsGrid>
        <CwsGridRow justifyContent="center">
          <CwsGridCol lg="8">
            <div className="text-center">
              <CwsH3>{translate('w2.pricelist.ship.select.header')}</CwsH3>
              <CwsP>{translate('w2.pricelist.ship.select.info')}</CwsP>
            </div>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>

      <ul className={clsx(moduleStyles.list, 'flex flex-wrap mt-8 bg-white hover:bg-seafoam box-border')}>
        {catalogs.map((catalog) => {
          return (
            <li key={catalog.id} className="w-1/2 tablet:w-1/3 shrink-0 box-border outline-group-grey outline-1">
              <Link href={`/${catalog.id}`} passHref className="w-full gap-y-2 gap-x-4 flex flex-col device800:flex-row py-5 px-6 items-center hover:bg-white">
                <div className="flex flex-col order-2 items-center device800:items-start justify-center gap-y-1">
                  <CwsP size={isMobile ? '' : 'lead'} weight="500" className={moduleStyles.label}>
                    {catalog.title}
                  </CwsP>
                  <CwsP className={moduleStyles.label} size={isMobile ? 'tiny' : 'small'}>
                    {catalog.info}
                  </CwsP>
                </div>
                <CwsIcon icon="ship" size={isMobile ? '16px' : '22px'} />
              </Link>
            </li>
          )
        })}
      </ul>
      <hr className="text-medium-grey mt-20" />
    </CwsSection>
  )
}

export default ShipSelection
