import type { FC } from 'react'
import s from '@components/home/HtmlSlider.module.scss'
import { CwsCarousel, CwsCarouselItem } from '@components/cws'
import Link from 'next/link'
import type { ImagePageBanner } from '@api/content'
import { CldImage } from 'next-cloudinary'

const BANNER_HEIGHT = '460'

export interface TopBannerSliderProps {
  imageBanners: Omit<ImagePageBanner, 'title'>[]
}

export const TopBannerSlider: FC<TopBannerSliderProps> = ({ imageBanners }) => {
  const shouldAutoplay = imageBanners.length > 1

  if (imageBanners.length === 1) {
    const [banner] = imageBanners
    return (
      <section className="mt-4">
        <Link key={banner.image} href={banner.clickUrl as string} passHref>
          <CldImage
            className="aspect-[1280/460]"
            alt={`image-banner-url-1`}
            loading="eager"
            width="1228"
            height={BANNER_HEIGHT}
            src={banner.image}
          />
        </Link>
      </section>
    )
  }

  return (
    <section>
      <CwsCarousel mode={'hero'} autoplay={shouldAutoplay}>
        {imageBanners.map(({ clickUrl, image }, idx) => {
          if (image.endsWith('html')) {
            return (
              <div key={image}>
                <iframe className={s.window} key={image} allowFullScreen={false} src={image} height={BANNER_HEIGHT} />
              </div>
            )
          }
          if (clickUrl)
            return (
              <CwsCarouselItem className="hover:opacity-90" key={image}>
                <Link key={image} href={clickUrl} passHref>
                  <CldImage
                    className="aspect-[1280/460]"
                    alt={`image-banner-url-${idx}`}
                    loading="eager"
                    width="1228"
                    height={BANNER_HEIGHT}
                    src={image}
                  />
                </Link>
              </CwsCarouselItem>
            )
          return (
            <CwsCarouselItem key={image}>
              <CldImage alt={`image-banner-url-${idx}`} loading="eager" width="1228" height={BANNER_HEIGHT} src={image} />
            </CwsCarouselItem>
          )
        })}
      </CwsCarousel>
    </section>
  )
}
